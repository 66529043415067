var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-flex",
    {
      staticClass: "d-flex justify-center",
      attrs: { cols: "12", id: "ExamDone" },
    },
    [
      _c(
        "v-flex",
        {
          staticClass: "justify-center d-flex flex-column",
          attrs: { xs12: "", md6: "" },
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-4",
                  attrs: { color: "#293241", dark: "", rounded: "xl" },
                },
                [
                  _c("v-img", {
                    staticClass: "my-3 mx-auto",
                    attrs: {
                      width: "350",
                      src: _vm.examToDoState.endImage,
                      "lazy-src": require("@/assets/images/placeholder.png"),
                    },
                  }),
                  _c(
                    "v-card-text",
                    { staticClass: "white--text text-center" },
                    [
                      _c("span", { staticClass: "f14 d-block" }, [
                        _vm._v(_vm._s(_vm.examToDoState.endText)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-row",
                [
                  _vm.examToDoState.examAssignStatus === "ANSWERED" &&
                  _vm.examToDoState.type === "PUBLIC"
                    ? _c(
                        "v-col",
                        { staticClass: "pa-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                color: "primary",
                                rounded: "xl",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.viewResult()
                                },
                              },
                            },
                            [_vm._v(" مشاهده نتیجه آزمون ")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        { staticClass: "pa-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                color: "primary",
                                rounded: "xl",
                                to: "/c/exams",
                              },
                            },
                            [_vm._v(" بازگشت به صفحه اصلی ")]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }