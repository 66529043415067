<template>
  <v-flex class="d-flex justify-center" cols="12" id="ExamDone">
    <v-flex class="justify-center d-flex flex-column" xs12 md6>
      <v-flex>
        <v-card color="#293241" class="pa-4" dark rounded="xl">
          <v-img width="350" class="my-3 mx-auto" :src="examToDoState.endImage" :lazy-src="require('@/assets/images/placeholder.png')"></v-img>
          <v-card-text class="white--text text-center">
            <span class="f14 d-block">{{examToDoState.endText}}</span>
          </v-card-text>
        </v-card>
      </v-flex>
      <div class="d-flex">
        <v-row>
          <v-col cols="12" class="pa-2"
            v-if="examToDoState.examAssignStatus === 'ANSWERED' && examToDoState.type === 'PUBLIC'">
            <v-btn block  color="primary" rounded="xl" @click="viewResult()">
              مشاهده نتیجه آزمون
            </v-btn>
          </v-col>
          <v-col cols="12" class="pa-2" v-else>
            <v-btn block color="primary" rounded="xl" :to="'/c/exams'">
              بازگشت به صفحه اصلی
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-flex>
  </v-flex>
</template>
<script>
export default {
  name: 'ExamDone',
  data: () => ({

  }),
  computed: {
    examToDoState: {
      get () {
        return this.$store.getters['exam/getExamToDo']
      },
      set (val) {
        this.$store.commit('exam/updateExamToDo', val)
      }
    }
  },
  mounted() {
    //do something after mounting vue instance
  },
  methods: {
    viewResult() {
      this.$router.push({ name: 'ExamResult' })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
